

.navigation {
  // display: block;
  height: 100%;
  overflow: auto;
  user-select: none;
  padding-bottom: 100px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 320px;
  box-sizing: border-box;
  background-color: #000;
  color: #fff;
  // transition: width 0.2s cubic-bezier(.5,0,.5,1);
  @media (max-width: 1000px) {
    flex-basis: 100%;
  }
  a {
    text-decoration: none;
  }
  ul {
    list-style: none;
  }

}
.main-navigation {
  margin: 20px 50px 0 50px;
  padding: 0;
  >li {
    font-size: 18px;
    line-height: 20px;
    > a {
      color: #fff;
      display: inline-block;
      margin: 20px 0 5px 0;
    }
  }
}
.sub-navigation {
  padding-left: 20px;

  >li {
    font-size: 16px;
    > a {
      display: inline-block;
      color: #fff;
      margin: 5px 0;
    }
    // list-style-type: '- ';
  }
}
.user {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 40px;

}
.services {
  span {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;;
  }
  margin: 20px 40px 0 40px;
}
.logo {
  font-family: NanumSquareAc;
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 44.2px;
  width: 100%;
  margin-top: 50px;
  text-align: center;
  margin-bottom: 36px;
  div {
    font-size: 30px;
    color: var(--theme-secondary-color);
  }
}
.router-link-active {
  border-bottom: 3px solid var(--theme-primary-color);
  font-weight: 800;

}
